
import React, { useState, useRef, useEffect } from 'react'
import apartment from '../data/apartmenData';
import cityData from '../data/cityData';
import rentData from "../data/rentData";
import villages from "../data/villages";
import landmarks from "../data/landmarks";
import metros from "../data/metros";
import districtData from '../data/districtData';
import trans from "../lang/translations";

const Search = () => {
    const lang = document.getElementById("root").dataset.lang
    const [searchQuery, setSearchQuery] = useState("")
    const [showRent, setShowRent] = useState(false)
    const [showApart, setShowApart] = useState(false);
    const [roomCount, setRoomCount] = useState(false);
    const [showDetail, setShowDetail] = useState(false);
    const [priceInput, setPriceInput] = useState(false);
    const [fieldInput, setFieldInput] = useState(false);
    const [sotFieldInput, setSotFieldInput] = useState(false);
    const [selectedRooms, setSelectedRooms] = useState([]);
    const selectedOption = rentData.find((data) => data.id == 1);
    const [selectRent, setSelectRent] = useState(selectedOption[`rent_name_${lang}`]);
    const [chooseRent, setChooseRent] = useState("1")
    const selectValue = apartment.find((data) => data.id == 0);
    const [selectedApartment, setSelectedApartment] = useState(selectValue[`apartmentName_${lang}`]);
    const [selec, setSelec] = useState(0)
    const [minPrice, setMinPrice] = useState('');
    const [maxPrice, setMaxPrice] = useState('');
    const [minkvmField, setMinkvmField] = useState('');
    const [maxkvmField, setMaxkvmField] = useState('');
    const [minsotField, setMinsotField] = useState('');
    const [maxsotField, setMaxsotField] = useState('');
    const [dminsot, setDminsot] = useState()
    const [dmaxsot, setDmaxsot] = useState()
    const [floorMin, setFloorMin] = useState()
    const [floorMax, setFloorMax] = useState()
    const [elan, setElan] = useState('')
    const roomCountRef = useRef();
    const searchInputRef = useRef();
    const [repairValue, setRepairValue] = useState([]);
    const [showplaceholder, setShowplaceholder] = useState(false)
    const [showplaceholderM, setShowplaceholderM] = useState(false)
    const [showplaceholderP, setShowplaceholderP] = useState(false)
    const [pInput, setPInput] = useState(minPrice && maxPrice ? `${minPrice} - ${maxPrice}` : `${trans[lang].price}, ${trans[lang].azn}`);
    const [sotInput, setSotInput] = useState(minsotField && maxsotField ? `${minsotField}-${maxsotField} ` : trans[`${lang}`].area)
    const [fInput, setFInput] = useState(minkvmField && maxkvmField ? `${minkvmField}-${maxkvmField}` : trans[`${lang}`].field)
    const roomPlaceholder = selectedRooms.length == 0 || roomCountRef.checked ? trans[`${lang}`].numberofrooms :  selectedRooms.length >=1 ? selectedRooms.join(', ') + trans[`${lang}`].rooms : selectedRooms.join(', ') + trans[`${lang}`].room;
    const [selectedCity, setSelectedCity] = useState([]);
    const [selectedLandmarks, setSelectedLandmarks] = useState([]);
    const [selectedRegion, setSelectedRegion] = useState([]);
    const [selectDistric, setSelectDistric] = useState([]);
    const [selectedMetro, setSelectedMetro] = useState([]);
    const detailWrapperRef = useRef(null);
    const rentWrapperRef = useRef(null);
    const roomWrapperRef = useRef(null);
    const apartWrapperRef = useRef(null);
    const priceWrapperRef = useRef(null);
    const fieldWrapperRef = useRef(null);
    const sotWrapperRef = useRef(null);
    const searchWrapperRef = useRef(null);
    const searchgroupRef = useRef();
    const [officetype, setOfficetype] = useState([]);
    const [selectbox, setSelectbox] = useState({
        barter: false,
        domestic_credit: false,
        credit: false,
        useful_mortgage: false,
        ready_mortgage: false,
        electric_line: false,
        water_barrel: false,
        water_line: false,
        items: false,
        combi: false,
        view_sea: false,
        near_sea: false,
        pool: false,
        gas: false,
        gas_line: false,
        kupcha: false,
        nofirstfloor: false,
        nolastfloor: false
    });
    const refStatePairs = [
        { ref: detailWrapperRef, stateSetter: setShowDetail },
        { ref: rentWrapperRef, stateSetter: setShowRent },
        { ref: roomWrapperRef, stateSetter: setRoomCount },
        { ref: apartWrapperRef, stateSetter: setShowApart },
        { ref: priceWrapperRef, stateSetter: setPriceInput },
        { ref: fieldWrapperRef, stateSetter: setFieldInput },
        { ref: sotWrapperRef, stateSetter: setSotFieldInput },
    ];
    const handleRentSelect = (id) => {
        const selectedOption = rentData.find((data) => data.id == id);
        setSelectRent(selectedOption[`rent_name_${lang}`]);
        setChooseRent(selectedOption.id);
        setShowRent(false);
    };
    const handleDocumentClick = (e) => {
        for (const { ref, stateSetter } of refStatePairs) {
            if (ref.current && !ref.current.contains(e.target)) {
                stateSetter(false);
            }
        }
    };
    document.addEventListener('click', handleDocumentClick);
    const handleResetStates = (stateToToggle) => {
        setShowRent(stateToToggle == 'showRent' ? !showRent : false);
        setShowApart(stateToToggle == 'showApart' ? !showApart : false);
        setRoomCount(stateToToggle == 'roomCount' ? !roomCount : false);
        setPriceInput(stateToToggle == 'priceInput' ? !priceInput : false);
        setFieldInput(stateToToggle == 'fieldInput' ? !fieldInput : false);
        setSotFieldInput(stateToToggle == 'sotFieldInput' ? !sotFieldInput : false);
        setShowDetail(stateToToggle == 'showDetail' ? !showDetail : false);
    };
    const handleCheckApartment = (id) => {
        const selectedValue = apartment.find((data) => data.id == id);
        setSelectedApartment(selectedValue[`apartmentName_${lang}`]);
        setSelec(selectedValue.id);
        setShowApart(false);
    };
    const formatWithSpaces = (value) => {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    };
    const handleInputChange = (e, stateSetter) => {
        const formattedValue = e.target.value.replace(/\D/g, '');
        stateSetter(formatWithSpaces(formattedValue));
    };
    const handleEnterKeyPress = (e) => {
        if (minPrice && maxPrice) {
            setPInput(`${minPrice} - ${maxPrice}`);
            setShowplaceholderP(true)
        } else if (minPrice) {
            setPInput(`${trans[lang].min} ${minPrice}`);
            setShowplaceholderP(true)
        } else if (maxPrice) {
            setPInput(`${trans[lang].max} ${maxPrice}`);
            setShowplaceholderP(true)
        } else {
            setPInput(`${trans[lang].price}, ${trans[lang].azn}`);
            setShowplaceholderP(false)
        }
        e.preventDefault()
    };
    const EnterKeyPressSotField = (e) => {
        if (minsotField && maxsotField) {
            setSotInput(`${minsotField} - ${maxsotField}`);
            setShowplaceholder(true)
        } else if (minsotField) {
            setSotInput(`${trans[lang].min} ${minsotField}`);
            setShowplaceholder(true)
        } else if (maxsotField) {
            setSotInput(`${trans[lang].max} ${maxsotField}`);
            setShowplaceholder(true)
        } else {
            setSotInput(trans[`${lang}`].area);
            setShowplaceholder(false)
        }
        e.preventDefault()
    };
    const EnterKeyPressField = (e) => {
        if (minkvmField && maxkvmField) {
            setFInput(`${minkvmField} - ${maxkvmField}`);
            setShowplaceholderM(true)
        } else if (minkvmField) {
            setFInput(`${trans[lang].min} ${minkvmField}`);
            setShowplaceholderM(true)
        } else if (maxkvmField) {
            setFInput(`${trans[lang].max} ${minkvmField}`);
            setShowplaceholderM(true)
        } else {
            setFInput(trans[`${lang}`].field);
            setShowplaceholderM(false)
        }
        e.preventDefault()
    };
    const handleRoomSelection = (e) => {
        const checkboxValue = e.target.value
        const isChecked = e.target.checked
        if (isChecked) {
            setSelectedRooms(prevSelectedRooms => [...prevSelectedRooms, checkboxValue].sort((a, b) => a - b));
        } else {
            setSelectedRooms(prevSelectedRooms =>
                prevSelectedRooms.filter(room => room != checkboxValue)
            );
        }
    };
    const handleAllCheckRoom = (e) => {
        const isChecked = e.target.checked;
        if (isChecked) {
            setSelectedRooms([]);
            const checkboxes = document.querySelectorAll('input[type="checkbox"][name="rooms[]"]');
            checkboxes.forEach((checkbox) => {
                if (checkbox != e.target) {
                    checkbox.checked = false;
                }
            });
            setRoomCount(false);
        }
    };
    const clearAllChecked = () => {
        setDminsot("")
        setDmaxsot("")
        setSelectedCity("")
        setSelectDistric("")
        setSelectedRegion("")
        setSelectedMetro("")
        setSelectedLandmarks("")
        setSelectRent(selectedOption[`rent_name_${lang}`])
        setChooseRent("1")
        setSelectedApartment(selectValue[`apartmentName_${lang}`])
        setSelec("0");
        setElan("")
        setMinPrice('');
        setMaxPrice('');
        setMinkvmField('');
        setMaxkvmField('');
        setMinsotField('');
        setMaxsotField('');
        setSelectedRooms('');
        setSotInput(trans[`${lang}`].area);
        setFInput(trans[`${lang}`].field);
        setPInput(`${trans[lang].price}, ${trans[lang].azn}`)
        setShowplaceholder(false)
        setShowplaceholderP(false)
        setShowplaceholderM(false)
        setFloorMax("")
        setFloorMin("")
        setRepairValue([])
        setShowDetail(false)
        setSelectbox({
            barter: false,
            domestic_credit: false,
            credit: false,
            useful_mortgage: false,
            ready_mortgage: false,
            electric_line: false,
            water_barrel: false,
            water_line: false,
            items: false,
            combi: false,
            view_sea: false,
            near_sea: false,
            pool: false,
            gas: false,
            gas_line: false,
            kupcha: false,
            nofirstfloor: false,
            nolastfloor: false

        })
    }
    const handleCityClick = (id) => {
        const isSelectedCity = selectedCity.includes(Number(id));
        if (isSelectedCity) {
            const updatedCity = selectedCity.filter((selectCity) => selectCity != id);
            setSelectedCity(updatedCity);
        } else {
            setSelectedCity([...selectedCity, id]);
        }
        searchInputRef.current.focus()
    }
    const handleRegionClick = (id) => {
        const isSelectedRegion = selectedRegion.includes(Number(id));
        if (isSelectedRegion) {
            const updatedRegion = selectedRegion.filter((selectRegion) => selectRegion != id);
            setSelectedRegion(updatedRegion);
        } else {
            setSelectedRegion([...selectedRegion, id]);
        }
        searchInputRef.current.focus()
    };
    const handleDistrictClick = (id) => {
        const isSelectedDistrict = selectDistric.includes(Number(id));
        if (isSelectedDistrict) {
            const updatedDistricts = selectDistric.filter((districtId) => districtId != id);
            setSelectDistric(updatedDistricts);
        } else {
            setSelectDistric([...selectDistric, id]);
        }
        searchInputRef.current.focus()
    };
    const handleMetroClick = (id) => {
        const isSelected = selectedMetro.includes(Number(id));
        if (isSelected) {
            const updatedMetro = selectedMetro.filter((selectedMetro) => selectedMetro != id);
            setSelectedMetro(updatedMetro);
        } else {
            setSelectedMetro([...selectedMetro, id]);
        }
        searchInputRef.current.focus()
    };
    const handleLandmarkClick = (id) => {
        const isSelected = selectedLandmarks.includes(Number(id));
        if (isSelected) {
            const updatedLandmarks = selectedLandmarks.filter((selectedLandmark) => selectedLandmark != id);
            setSelectedLandmarks(updatedLandmarks);
        } else {
            setSelectedLandmarks([...selectedLandmarks, id]);
        }
        searchInputRef.current.focus()
    }
    const clearSearch = () => {
        setSearchQuery("")
    }
    const handleCheckboxChange = (name) => {
        setSelectbox((prevSelectbox) => ({
            ...prevSelectbox,
            [name]: !prevSelectbox[name],
        }));
    };
    const handleBuldingCheck = (value) => {
        if (officetype.includes(value)) {
            setOfficetype(prevTypes => prevTypes.filter(type => type != value));
        } else {
            setOfficetype(prevTypes => [...prevTypes, value]);
        }
    };
    const handleRadioChange = (value) => {
        if (repairValue.includes(value)) {
            setRepairValue(prevTypes => prevTypes.filter(type => type != value));
        } else {
            setRepairValue(prevTypes => [...prevTypes, value]);
        }
    };

    useEffect(() => {
        function handleClickOutside(event) {
            if (searchWrapperRef.current && !searchWrapperRef.current.contains(event.target)) {
                setSearchQuery('');
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [searchWrapperRef]);

    return (
        <div className='container-fluid'>
            <form action="/elanlar" method="GET" autoComplete='off' encType="multipart/form-data">
                <div className="wx-search-container-section">
                    <div className='wx-search-container'>
                        <div className='wx-search-row'>
                            <div className='wx-search d-flex' id='menu' ref={rentWrapperRef} onClick={() => handleResetStates('showRent')}>
                                <div className='wx-rent-wrapper' >
                                    <div className='wx-rent-input'>
                                        <input type="text" placeholder={selectRent} readOnly />
                                        <input name="type" type="hidden" value={chooseRent} />
                                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="currentColor" className={`bi bi-chevron-down ${showRent ? 'icon-rotate' : "icon"}`} viewBox="0 0 16 16">
                                            <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                                        </svg>
                                    </div>
                                    <div className={`wx-rent-list wx-list wx-list-style ${showRent ? 'active' : 'deactive'}`}>
                                        {rentData.map((data, id) => {
                                            return <ul key={id}>
                                                <li onClick={() => handleRentSelect(Number(data.id))}>
                                                    {data[`rent_name_${lang}`]}
                                                    <svg style={{ display: chooseRent == Number(data.id) ? "inline-block" : "none", color: "#43a5f5" }} xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                                                        className='bi bi-check'
                                                        fill="currentColor" viewBox="0 0 16 16">
                                                        <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z" />
                                                    </svg>
                                                </li>
                                            </ul>
                                        })}
                                    </div>
                                </div>
                            </div>
                            <div className='wx-search' id='wx-location-search' ref={searchWrapperRef} >
                                <div className="wx-search-section">
                                    <div className='wx-search-input d-flex'>
                                        <input className='wx-modal-input' type="text" placeholder={trans[`${lang}`].locationtitle}
                                            value={searchQuery} ref={searchInputRef}
                                            onChange={(e) => setSearchQuery(e.target.value)} />
                                        {searchQuery ? (
                                            <div onClick={clearSearch}>
                                               <img src="/icons/close.png" alt="map pin" width={30}/></div>) : (
                                            <div>
                                                <img src="/icons/search-location.png" alt="map pin" width={30}/>
                                            </div>)}
                                    </div>

                                    <div className="wx-search-result-section">
                                        <ul>
                                            {searchQuery && (
                                                districtData
                                                    .filter((district) =>
                                                        district[`districtname_${lang}`].toLowerCase().startsWith(searchQuery.toLowerCase()))
                                                    .map((district, index) => (
                                                        <li key={index} onClick={() => handleDistrictClick(Number(district.district_id))}>
                                                            <div className='d-flex flex-column wx-location-name-box '>
                                                                <p className='wx-location-name'>{district[`districtname_${lang}`]}</p>
                                                                <p className='wx-location-title'>{trans[`${lang}`].district}</p>
                                                            </div>
                                                            <svg style={{ display: selectDistric.includes(Number(district.district_id)) ? "inline-block" : "none", color: "#43a5f5" }} xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                                                                className='bi bi-check'
                                                                fill="currentColor" viewBox="0 0 16 16">
                                                                <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z" />
                                                            </svg>
                                                        </li>
                                                    ))
                                            )}
                                            {searchQuery && (
                                                villages
                                                    .filter((village) =>
                                                        village[`village_${lang}`].toLowerCase().startsWith(searchQuery.toLowerCase())
                                                    )
                                                    .map((village, id) => (
                                                        <li key={id} onClick={() => handleRegionClick(Number(village.id))}>
                                                            <div className='d-flex flex-column wx-location-name-box '>
                                                                <p className='wx-location-name'>{village[`village_${lang}`]}</p>
                                                                <p className='wx-location-title'>{trans[`${lang}`].villiage}</p>
                                                            </div>
                                                            <svg style={{
                                                                display: selectedRegion.includes(Number(village.id)) ? "inline-block" : "none",
                                                            }} xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                                                                className='bi bi-check'
                                                                fill="currentColor" viewBox="0 0 16 16">
                                                                <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z" />
                                                            </svg>
                                                        </li>
                                                    ))
                                            )}
                                            {searchQuery && (
                                                metros
                                                    .filter((metro) =>
                                                        metro[`metro_${lang}`].toLowerCase().startsWith(searchQuery.toLowerCase()))
                                                    .map((metro, id) => (
                                                        <li key={id} onClick={() => handleMetroClick(Number(metro.id))}>
                                                            <div className='d-flex flex-column wx-location-name-box '>
                                                                <p className='wx-location-name'>{metro[`metro_${lang}`]}</p>
                                                                <p className='wx-location-title'>{trans[`${lang}`].metro}</p>
                                                            </div>
                                                            <svg style={{ display: selectedMetro.includes(Number(metro.id)) ? "inline-block" : "none", color: "#43a5f5" }} xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                                                                className='bi bi-check'
                                                                fill="currentColor" viewBox="0 0 16 16">
                                                                <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z" />
                                                            </svg>
                                                        </li>
                                                    )))}
                                            {searchQuery && (
                                                landmarks.filter((landmark) =>
                                                    landmark[`landmark_${lang}`].toLowerCase().startsWith(searchQuery.toLowerCase())
                                                ).map((landmark, id) => (
                                                    <li key={id} onClick={() => handleLandmarkClick(Number(landmark.id))}>
                                                        <div className='d-flex flex-column wx-location-name-box '>
                                                            <p className='wx-location-name'>{landmark[`landmark_${lang}`]}</p>
                                                            <p className='wx-location-title'>{trans[`${lang}`].landmark}</p>
                                                        </div>
                                                        <svg style={{ display: selectedLandmarks.includes(Number(landmark.id)) ? "inline-block" : "none", color: "#43a5f5" }} xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                                                            className='bi bi-check'
                                                            fill="currentColor" viewBox="0 0 16 16">
                                                            <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z" />
                                                        </svg>
                                                    </li>
                                                ))
                                            )}
                                            {searchQuery && (
                                                cityData
                                                    .filter((city) =>
                                                        city[`cityname_${lang}`].toLowerCase().startsWith(searchQuery.toLowerCase()))
                                                    .map((city, index) => (
                                                        <li key={index} onClick={() => handleCityClick(Number(city.city_id))}>
                                                            <div className='d-flex flex-column wx-location-name-box '>
                                                                <p className='wx-location-name'>{city[`cityname_${lang}`]}</p>
                                                                <p className='wx-location-title'>{trans[`${lang}`].city}</p>
                                                            </div>
                                                            <svg style={{ display: selectedCity.includes(Number(city.city_id)) ? "inline-block" : "none", color: "#43a5f5" }} xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                                                                className='bi bi-check'
                                                                fill="currentColor" viewBox="0 0 16 16">
                                                                <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z" />
                                                            </svg>
                                                        </li>
                                                    ))
                                            )}
                                            {(searchQuery &&
                                                cityData.filter((city) =>
                                                    city[`cityname_${lang}`].toLowerCase().startsWith(searchQuery.toLowerCase())
                                                ).length == 0 &&
                                                districtData.filter((district) =>
                                                    district[`districtname_${lang}`].toLowerCase().startsWith(searchQuery.toLowerCase())
                                                ).length == 0 &&
                                                villages.filter((village) =>
                                                    village[`village_${lang}`].toLowerCase().startsWith(searchQuery.toLowerCase())
                                                ).length == 0 &&
                                                metros.filter((metro) =>
                                                    metro[`metro_${lang}`].toLowerCase().startsWith(searchQuery.toLowerCase())
                                                ).length == 0 &&
                                                landmarks.filter((landmark) =>
                                                    landmark[`landmark_${lang}`].toLowerCase().startsWith(searchQuery.toLowerCase())
                                                ).length == 0) && (
                                                    <li className="no-results"><p>{trans[`${lang}`].notfinedresult} <span className="fn-18"> &#9785;</span> </p></li>
                                                )}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className='wx-search' id='apartment' ref={apartWrapperRef} onClick={() => handleResetStates('showApart')}>
                                <div className='wx-apartment-wrapper'>
                                    <div className='wx-apartment-input d-flex align-items-center justify-content-center'>
                                        <input type="text" placeholder={selectedApartment} readOnly />
                                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="currentColor" className={`bi bi-chevron-down ${showApart ? 'icon-rotate' : "icon"}`} viewBox="0 0 16 16">
                                            <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                                        </svg>
                                    </div>
                                    <div className={`wx-apartment-list wx-list wx-list-style ${showApart ? 'active' : 'deactive'}`} onClick={(e) => e.stopPropagation()}>
                                        <input type="hidden" name="category" value={selec} />
                                        <ul>
                                            {apartment.map((data, index) => {
                                                return <li onClick={() => handleCheckApartment(data.id)} key={index}>{data[`apartmentName_${lang}`]}
                                                    <svg style={{ display: selec == Number(data.id) ? "inline-block" : "none", color: "#43a5f5" }} xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                                                        className='bi bi-check'
                                                        fill="currentColor" viewBox="0 0 16 16">
                                                        <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z" />
                                                    </svg>
                                                </li>
                                            })}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="wx-search-container">
                        <div className="wx-search-row">
                            <div className={`wx-search ${(selec == 5 || selec == 6 || selec == 7 || selec == 8) ? 'mydisabled' : ""}`} id='rooms' ref={roomWrapperRef} onClick={() => handleResetStates('roomCount')}>
                                <>
                                    <div className='wx-rooms-inputs'>
                                        <input className={`${(selec == 5 || selec == 6 || selec == 7 || selec == 8) ? 'mydisabled' : ""}`} type="text" ref={roomCountRef} placeholder={roomPlaceholder} readOnly />
                                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="currentColor" className={`bi bi-chevron-down ${roomCount && (selec == 5 && selec == 8) ? 'icon-rotate' : "icon"}`} viewBox="0 0 16 16">
                                            <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                                        </svg>
                                    </div>
                                    <div className={` ${(selec == 5 || selec == 6 || selec == 7 || selec == 8) ? 'disabled' : 'rooms-wrapper'}`}  >
                                        <div className={`wx-room-list wx-list wx-list-style ${roomCount ? 'active' : 'deactive'}`} onClick={(e) => e.stopPropagation()}>
                                            <ul>
                                                <li>
                                                    <label className="wx-checkbox-label">
                                                        {trans[`${lang}`].anynumberofrooms}
                                                        <input checked={selectedRooms.length == 0} type="checkbox" onChange={handleAllCheckRoom} />
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="wx-checkbox-label">
                                                        1 {trans[`${lang}`].room}
                                                        <input type="checkbox" value="1" name="rooms[]" checked={selectedRooms.includes("1")} onChange={handleRoomSelection} />
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="wx-checkbox-label">
                                                        2 {trans[`${lang}`].rooms}
                                                        <input type="checkbox" value="2" name="rooms[]" checked={selectedRooms.includes("2")} onChange={handleRoomSelection} />
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="wx-checkbox-label">
                                                        3 {trans[`${lang}`].rooms}
                                                        <input id='otaq3' value="3" type="checkbox" name="rooms[]" checked={selectedRooms.includes("3")} onChange={handleRoomSelection} />
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="wx-checkbox-label">
                                                        4 {trans[`${lang}`].rooms}
                                                        <input type="checkbox" name="rooms[]" value="4" checked={selectedRooms.includes("4")} onChange={handleRoomSelection} />
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="wx-checkbox-label">
                                                        5 {trans[`${lang}`].roomsmore}
                                                        <input type="checkbox" value="5" name="rooms[]" checked={selectedRooms.includes("5")} onChange={handleRoomSelection} />
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </>
                            </div>
                            {selec != 5 &&
                                <div className='wx-search' ref={fieldWrapperRef}
                                    onClick={() => {
                                        handleResetStates("fieldInput")
                                    }} >
                                    <div>
                                        <div className="wx-showplace">
                                            <div className={`inputbox ${showplaceholderM ? 'active' : 'deactive'}`}> <span>{trans[`${lang}`].field}</span></div>
                                            <div className='field-wrapper  field-inp '>
                                                <input type="text" placeholder={fInput} readOnly />
                                            </div>
                                        </div>
                                        <div className={`wx-field wx-list ${fieldInput ? 'active' : 'deactive'}`} onBlur={EnterKeyPressField} >
                                            <div className="d-flex" >
                                                <div>
                                                <span>{trans[`${lang}`].minimum}</span>
                                                <div className='wx-field-input  me-2' onClick={(e) => e.stopPropagation()}>
                                                    <input name="minkvm" className='wx-min-input' type="text"  value={minkvmField} onChange={(e) => handleInputChange(e, setMinkvmField)} />
                                                </div>
                                                </div>
                                                <div>
                                                <span className={minkvmField && maxkvmField && typeof minkvmField === 'string' && typeof maxkvmField === 'string' && parseInt(minkvmField.replace(/\D/g, ''))>=parseInt(maxkvmField.replace(/\D/g, '')) ? `text-danger` : ""}>{trans[`${lang}`].maximum}</span>
                                                <div className='wx-field-input' onClick={(e) => e.stopPropagation()}>
                                                    <input name="maxkvm" className={`wx-max-input ${minkvmField && maxkvmField && typeof minkvmField === 'string' && typeof maxkvmField === 'string' && parseInt(minkvmField.replace(/\D/g, ''))>=parseInt(maxkvmField.replace(/\D/g, '')) ? `text-danger` : ""}`} type="text"  value={maxkvmField} onChange={(e) => handleInputChange(e, setMaxkvmField)} />
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {selec == 5 &&
                                <div className='wx-search' ref={sotWrapperRef} onClick={() => handleResetStates("sotFieldInput")} >
                                    <div className="wx-showplace" >
                                        <div className={`inputbox ${showplaceholder ? 'active' : 'deactive'}`}> <span>{trans[`${lang}`].area}</span></div>
                                        <div className='wx-field-wrapper  field-inp '>
                                            <input type="text" placeholder={sotInput} readOnly />
                                        </div>
                                    </div>
                                    <div className={`wx-field wx-list ${sotFieldInput ? 'active' : 'deactive'}`} onBlur={EnterKeyPressSotField}>
                                        <div className="d-flex" >
                                            <div>
                                            <span>{trans[`${lang}`].minimum}</span>
                                            <div className='wx-field-input  me-2' onClick={(e) => e.stopPropagation()}>
                                                <input name="minsot" maxLength={12} className='wx-min-input' type="text" value={minsotField} onChange={(e) => handleInputChange(e, setMinsotField)} />
                                            </div>
                                            </div>
                                            <div>
                                            <span className={minsotField && maxsotField && typeof minsotField === 'string' && typeof maxsotField === 'string' && parseInt(minsotField.replace(/\D/g, ''))>=parseInt(maxsotField.replace(/\D/g, '')) ? `text-danger` : ""}>{trans[`${lang}`].maximum}</span>
                                            <div className='wx-field-input' onClick={(e) => e.stopPropagation()}>
                                                <input name="maxsot" maxLength={12} className={`wx-range-input ${minsotField && maxsotField && typeof minsotField === 'string' && typeof maxsotField === 'string' && parseInt(minsotField.replace(/\D/g, ''))>=parseInt(maxsotField.replace(/\D/g, '')) ? `text-danger` : ""}`} type="text" value={maxsotField} onChange={(e) => handleInputChange(e, setMaxsotField)} />
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>}
                            <div className='wx-search ' ref={priceWrapperRef} onClick={() => handleResetStates('priceInput')}>
                                <div className="wx-showplace" >
                                    <div className={`inputbox ${showplaceholderP ? 'active' : 'deactive'}`}> <span>{trans[`${lang}`].price}, {trans[`${lang}`].azn}</span></div>
                                    <div className=' price-wrapper d-flex align-center  wx-price-inp'>
                                        <input type="text" placeholder={pInput} readOnly />
                                    </div>
                                </div>
                                <div className={`wx-price wx-list ${priceInput ? 'active' : 'deactive'}`} onBlur={handleEnterKeyPress}>
                                    <div className="d-flex">
                                        <div>
                                            <span>{trans[`${lang}`].minimum}</span>
                                            <div className='wx-price-input min-inpt me-2' onClick={(e) => e.stopPropagation()}>
                                                <input name="pmin" className='wx-min-input' maxLength={12} type="text" value={minPrice} onChange={(e) => handleInputChange(e, setMinPrice)} />
                                            </div>
                                        </div>
                                        <div>
                                            <span className={minPrice && maxPrice && typeof minPrice === 'string' && typeof maxPrice === 'string' && parseInt(minPrice.replace(/\D/g, ''))>=parseInt(maxPrice.replace(/\D/g, '')) ? `text-danger` : ""}>{trans[`${lang}`].maximum}</span>
                                        <div className='wx-price-input' onClick={(e) => e.stopPropagation()}>
                                            <input name="pmax" className={`wx-max-input ${minPrice && maxPrice && typeof minPrice === 'string' && typeof maxPrice === 'string' && parseInt(minPrice.replace(/\D/g, ''))>=parseInt(maxPrice.replace(/\D/g, '')) ? 'text-danger' : ''}`} maxLength={12} type="text" value={maxPrice} onChange={(e) => handleInputChange(e, setMaxPrice)} />
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="wx-search button-section">
                                <div className='wx-detail-wrapper' >
                                    <div className='wx-search-more-icons'>
                                        <div ref={detailWrapperRef} onClick={() => handleResetStates('showDetail')} className='p-1'>
                                            <img src="/icons/filter.png" alt="search filter" width={25}/>
                                        </div>
                                        <div id="wx-buton" >
                                            <button type="submit" className='btn wx-btn'>{trans[`${lang}`].dosearch}</button>
                                        </div>
                                    </div>
                                    <div className={`wx-detailed-search ${showDetail ? 'active' : 'deactive'}`} onClick={(e) => e.stopPropagation()}>
                                        <div className="wx-search-locations" >
                                            <div>
                                                <div className="wx-search-locations-buttons d-flex" >
                                                    <div className='wx-search-elan'>
                                                        {selec != 5 && selec != 6 &&
                                                            <div className="wx-search-group" ref={searchgroupRef}>
                                                                <div className="wx-more-detail_search wx-search-radio-group d-flex">
                                                                    <label className='new-checkbox_btn-label'>
                                                                        <input value={1} type="checkbox" name="repair[]" className='wx-checkbox_btn ' checked={repairValue.includes(1)}   onChange={() => handleRadioChange(1)}
                                                                        />
                                                                        <span className='wx-radio-btn'>{trans[`${lang}`].normalrepaired}</span>
                                                                    </label>
                                                                    <label className='new-checkbox_btn-label'>
                                                                        <input value={2} type="checkbox" name="repair[]" checked={repairValue.includes(2)} onChange={() => handleRadioChange(2)}
                                                                        />
                                                                        <span className='wx-radio-btn'>{trans[`${lang}`].goodrepaired}</span>
                                                                    </label>
                                                                    <label className='new-checkbox_btn-label'>
                                                                        <input value={0} type="checkbox" name="repair[]" checked={repairValue.includes(0)} onChange={() => handleRadioChange(0)}
                                                                        />
                                                                        <span className='wx-radio-btn'>{trans[`${lang}`].norepaired}</span>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                    <div className="wx-search-regular wx-more-detail_search d-flex">
                                                        <label className="wx-checkbox_btn-label h-38">
                                                            <input type="checkbox" name="kupcha" checked={selectbox.kupcha}
                                                                onChange={() => handleCheckboxChange('kupcha')} />
                                                            <span className='wx-checkbox_btn'>{trans[`${lang}`].kupcha}</span>
                                                        </label>

                                                        <div className='wx-elan-number ms-3' >
                                                            <label></label>
                                                                <input name="adsnumber" maxLength={12}  placeholder={trans[`${lang}`].adsnumber} type="text" className='adsnumber' value={elan} onChange={(e) => {if (/^[0-9]*$/.test(e.target.value)) {setElan(e.target.value);}}} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div>
                                                <div className='wx-area mt-2'>
                                                    {(selec == 3 || selec == 9) && (
                                                        <div>
                                                            <div className='wx-search-label'>
                                                                <span>{trans[`${lang}`].landarea}</span>
                                                            </div>
                                                            <div className='wx-range-inputs'>
                                                                <input value={dminsot} name="minsot" maxLength={12} type="text" className='wx-range-input' placeholder={trans[`${lang}`].min}
                                                                    onChange={(e) => handleInputChange(e, setDminsot)} />
                                                                <input value={dmaxsot} name="maxsot" maxLength={12} type="text" className='wx-range-input' placeholder={trans[`${lang}`].max} onChange={(e) => handleInputChange(e, setDmaxsot)} />
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                                {(selec == 0 || selec == 1 || selec == 2) && (
                                                    <div className='wx-floor'>
                                                        <div>
                                                            <div className='wx-floor-label'>
                                                                <span>{trans[`${lang}`].floor}</span>
                                                            </div>
                                                            <div className='wx-range-inputs'>
                                                                <input name="floor_min"  value={floorMin} type="text" maxLength={2} className='wx-range-input' placeholder={trans[`${lang}`].min}  onChange={(e) => setFloorMin(e.target.value)}  onKeyPress={(e) => {
                                                                    const isValidInput = /^-?[0-9,'.',' ']*$/.test(e.target.value + e.key);
                                                                    if (!isValidInput) {
                                                                    e.preventDefault();
                                                                    }
                                                                }} />
                                                                <input name="floor_max" value={floorMax} type="text" maxLength={2} className='wx-range-input' placeholder={trans[`${lang}`].max} onChange={(e) => setFloorMax(e.target.value)}  onKeyPress={(e) => {
                                                                const isValidInput = /^-?[0-9,'.',' ']*$/.test(e.target.value + e.key);
                                                                if (!isValidInput) {
                                                                e.preventDefault();
                                                                }
                                                            }} />
                                                            </div>
                                                        </div>
                                                        <div className='wx-checkbox-floor wx-more-detail_search ms-3'>
                                                            <label className="wx-checkbox_btn-label h-38">
                                                                <input type="checkbox" name="nofirstfloor" checked={selectbox.nofirstfloor}
                                                                    onChange={() => handleCheckboxChange('nofirstfloor')} />
                                                                <span className='wx-checkbox_btn'>{trans[`${lang}`].nofirstfloor}</span>
                                                            </label>
                                                            <label className="wx-checkbox_btn-label h-38">
                                                                <input type="checkbox" name="nolastfloor" checked={selectbox.nolastfloor} onChange={() => handleCheckboxChange('nolastfloor')} />
                                                                <span className='wx-checkbox_btn'>{trans[`${lang}`].notopfloor}</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                            {selec == 4 && (
                                                <div className='wx-building-type'>
                                                    <div className='wx-building-label'>
                                                        <span>{trans[`${lang}`].typeofbuilding}</span>
                                                    </div>
                                                    <div className='wx-checkbox-building wx-more-detail_search'>
                                                        <label className="wx-checkbox_btn-label">
                                                            <input
                                                                value="1"
                                                                name="officetype[]"
                                                                type="checkbox"
                                                                checked={officetype.includes("1")}
                                                                onChange={() => handleBuldingCheck("1")}
                                                            />
                                                            <span className='q'>{trans[`${lang}`].businesscenter}</span>
                                                        </label>
                                                        <label className="wx-checkbox_btn-label">
                                                            <input
                                                                value="2"
                                                                name="officetype[]"
                                                                type="checkbox"
                                                                checked={officetype.includes("2")}
                                                                onChange={() => handleBuldingCheck("2")}
                                                            />
                                                            <span className='wx-checkbox_btn'>{trans[`${lang}`].homebuilding}</span>
                                                        </label>
                                                        <label className="wx-checkbox_btn-label">
                                                            <input
                                                                value="3"
                                                                name="officetype[]"
                                                                type="checkbox"
                                                                checked={officetype.includes("3")}
                                                                onChange={() => handleBuldingCheck("3")}
                                                            />
                                                            <span className='wx-checkbox_btn'>{trans[`${lang}`].villa}</span>
                                                        </label>
                                                        <label className="wx-checkbox_btn-label">
                                                            <input
                                                                value="4"
                                                                name="officetype[]"
                                                                type="checkbox"
                                                                checked={officetype.includes("4")}
                                                                onChange={() => handleBuldingCheck("4")}
                                                            />
                                                            <span className='wx-checkbox_btn'>{trans[`${lang}`].coworking}</span>
                                                        </label>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <div className='wx-more-detail mt-3'>
                                            {chooseRent == 1 && (
                                                <div className='wx-more-detail_search d-flex'>
                                                    <label className='wx-checkbox_btn-label'>
                                                        <input className='wx-checkbox_btn'
                                                            type="checkbox"
                                                            name="barter"
                                                            checked={selectbox.barter}
                                                            onChange={() => handleCheckboxChange('barter')}
                                                        />
                                                        <span>{trans[`${lang}`].barter}</span>
                                                    </label>
                                                    <label className='wx-checkbox_btn-label'>
                                                        <input
                                                            name="domestic_credit"
                                                            className='wx-checkbox_btn'
                                                            type="checkbox"
                                                            checked={selectbox.domestic_credit}
                                                            onChange={() => handleCheckboxChange('domestic_credit')}
                                                        />
                                                        <span>{trans[`${lang}`].internalcredit}</span>
                                                    </label>
                                                    <label className='wx-checkbox_btn-label'>
                                                        <input
                                                            name="credit"
                                                            className='wx-checkbox_btn'
                                                            type="checkbox"
                                                            checked={selectbox.credit}
                                                            onChange={() => handleCheckboxChange('credit')}
                                                        />
                                                        <span>{trans[`${lang}`].credit}</span>
                                                    </label>
                                                    <label className='wx-checkbox_btn-label'>
                                                        <input
                                                            name="useful_mortgage"
                                                            className='wx-checkbox_btn'
                                                            type="checkbox"
                                                            checked={selectbox.useful_mortgage}
                                                            onChange={() => handleCheckboxChange('useful_mortgage')}
                                                        />
                                                        <span>{trans[`${lang}`].usefullmortgage}</span>
                                                    </label>
                                                    <label className='wx-checkbox_btn-label'>
                                                        <input
                                                            name="ready_mortgage"
                                                            className='wx-checkbox_btn'
                                                            type="checkbox"
                                                            checked={selectbox.ready_mortgage}
                                                            onChange={() => handleCheckboxChange('ready_mortgage')}
                                                        />
                                                        <span>{trans[`${lang}`].readymortgage}</span>
                                                    </label>
                                                </div>
                                            )}
                                            {selec != 8 &&
                                                <>
                                                    {selec != 5 && selec != 6 && selec != 7 &&
                                                        <div className='wx-more-detail_search mt-3 d-flex'>
                                                                <label className='wx-checkbox_btn-label'>
                                                                    <input name="items" className='wx-checkbox_btn' type="checkbox"
                                                                        checked={selectbox.items}
                                                                        onChange={() => handleCheckboxChange('items')} />
                                                                    <span>{trans[`${lang}`].items}</span>
                                                                </label>
                                                            {selec != 4 &&
                                                                <>
                                                                    <label className='wx-checkbox_btn-label'>
                                                                        <input name="gas" className='wx-checkbox_btn' type="checkbox" checked={selectbox.gas}
                                                                            onChange={() => handleCheckboxChange('gas')} />
                                                                        <span>{trans[`${lang}`].gas}</span>
                                                                    </label>
                                                                    <label className='wx-checkbox_btn-label'>
                                                                        <input name="water_barrel" className='wx-checkbox_btn' type="checkbox" checked={selectbox.water_barrel}
                                                                            onChange={() => handleCheckboxChange('water_barrel')} />
                                                                        <span>{trans[`${lang}`].waterbarrel}</span>
                                                                    </label>
                                                                    <label className='wx-checkbox_btn-label'>
                                                                        <input name="combi" className='wx-checkbox_btn' type="checkbox" checked={selectbox.combi}
                                                                            onChange={() => handleCheckboxChange('combi')} />
                                                                        <span>{trans[`${lang}`].combi}</span>
                                                                    </label>
                                                                    <label className='wx-checkbox_btn-label'>
                                                                        <input name="view_sea" className='wx-checkbox_btn' type="checkbox" checked={selectbox.view_sea}
                                                                            onChange={() => handleCheckboxChange('view_sea')} />
                                                                        <span>{trans[`${lang}`].viewsea}</span>
                                                                    </label>
                                                                </>
                                                                }
                                                           
                                                            {(selec == 3 || selec == 9) && (
                                                                <>
                                                                    <label className='wx-checkbox_btn-label'>
                                                                        <input name="pool" className='wx-checkbox_btn' type="checkbox" checked={selectbox.pool}
                                                                            onChange={() => handleCheckboxChange('pool')} />
                                                                        <span>{trans[`${lang}`].pool}</span>
                                                                    </label>

                                                                    <label className='wx-checkbox_btn-label'>
                                                                        <input name="near_sea" className='wx-checkbox_btn' type="checkbox" checked={selectbox.near_sea}
                                                                            onChange={() => handleCheckboxChange('near_sea')} />
                                                                        <span>{trans[`${lang}`].nearsea}</span>
                                                                    </label>
                                                                </>
                                                            )}
                                                        </div>
                                                    }

                                                    <div className='wx-more-detail_search d-flex mt-3'>
                                                        {selec == 5 &&
                                                            <label className='wx-checkbox_btn-label'>
                                                                <input name="electric_line" className='wx-checkbox_btn' type="checkbox" checked={selectbox.electric_line}
                                                                    onChange={() => handleCheckboxChange('electric_line')} />
                                                                <span>{trans[`${lang}`].electricline}</span>
                                                            </label>
                                                        }
                                                        {selec == 5 &&
                                                            <label className='wx-checkbox_btn-label'>
                                                                <input name="water_line" className='wx-checkbox_btn' type="checkbox" checked={selectbox.water_line}
                                                                    onChange={() => handleCheckboxChange('water_line')} />
                                                                <span>{trans[`${lang}`].waterline}</span>
                                                            </label>}
                                                        {selec == 5 &&
                                                            <label className='wx-checkbox_btn-label'>
                                                                <input name="gas_line" className='wx-checkbox_btn' type="checkbox" checked={selectbox.gas_line}
                                                                    onChange={() => handleCheckboxChange('gas_line')} />
                                                                <span>{trans[`${lang}`].gasline}</span>
                                                            </label>}
                                                    </div>
                                                </>
                                            }
                                        </div>
                                        <div className='wx-search-foot mt-3'>
                                            <div className='hiddenn' onClick={() => setShowDetail(false)}>
                                                <span>{trans[`${lang}`].hide}</span>
                                                <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-chevron-up ms-1" viewBox="0 0 16 16">
                                                    <path fillRule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z" />
                                                </svg>
                                            </div>
                                            <div className='wx-buttons'>
                                                <div className='wx-btnClearAll' onClick={clearAllChecked}>
                                                    <input type="reset" value={trans[`${lang}`].reset} />
                                                </div>
                                                <button className='btn wx-btn-show'><span>{trans[`${lang}`].showannouns}</span></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='wx-addAllChecked'>
                        <ul className='d-flex'>
                            {selectedCity &&
                                selectedCity.filter((data) => data.id == cityData.city_id)
                                    .map((item, i) => (
                                        <li key={i} onClick={() => handleCityClick(Number(item))}>
                                            <input name='city_id[]' value={selectedCity[i]} type="hidden" />
                                            <div className='wx-box'>
                                                <span>{cityData[item - 1][`cityname_${lang}`]}</span>
                                                <span className='wx-title'>{trans[`${lang}`].city}</span>
                                            </div>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-x ms-2" viewBox="0 0 16 16">
                                                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                            </svg>
                                        </li>
                                    ))
                            }
                            {selectDistric &&
                                selectDistric.filter((data) => data.id == districtData.district_id).map((item, i) => (
                                    <li key={i} onClick={() => handleDistrictClick(Number(item))}>
                                        <input name='district_id[]' value={selectDistric[i]} type="hidden" />
                                        <div className='wx-box'>
                                            <span>  {districtData[item - 1][`districtname_${lang}`]}</span>
                                            <span className='wx-title'>{trans[`${lang}`].district}</span>
                                        </div>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                            fill="currentColor"
                                            className="bi bi-x mb-1"
                                            viewBox="0 0 16 16"
                                        >
                                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                        </svg>
                                    </li>
                                ))
                            }
                            {selectedRegion &&
                                selectedRegion.filter((data) => data.id == villages.id)
                                    .map((item, i) => (
                                        <li key={i} onClick={() => handleRegionClick(Number(item))}>
                                            <input name='villageid[]' value={selectedRegion[i]} type="hidden" />
                                            <div className='wx-box'>
                                                <span>{villages[item - 1][`village_${lang}`]}</span>
                                                <span className='wx-title'>{trans[`${lang}`].villiage}</span>
                                            </div>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-x ms-2" viewBox="0 0 16 16">
                                                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                            </svg>
                                        </li>
                                    ))
                            }
                            {selectedMetro &&
                                selectedMetro.filter((data) => data.id == metros.id).map((item, i) => (
                                    <li key={i} onClick={() => handleMetroClick(Number(item))}>
                                        <input name='metroid[]' value={selectedMetro[i]} type="hidden" />
                                        <div className='wx-box'>
                                            <span> {metros[item - 1][`metro_${lang}`]}</span>
                                            <span className='wx-title'>{trans[`${lang}`].metro}</span>
                                        </div>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-x " viewBox="0 0 16 16">
                                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                        </svg>
                                    </li>
                                ))}
                            {selectedLandmarks && selectedLandmarks.filter((data) => data.id == landmarks.id).map((item, i) => (
                                <li key={i} onClick={() => handleLandmarkClick(Number(item))}>
                                    <input name='landmarkid[]' value={selectedLandmarks[i]} type="hidden" />
                                    <div className='wx-box'>
                                        <span>{landmarks[item - 1][`landmark_${lang}`]}</span>
                                        <span className='wx-title'>{trans[`${lang}`].landmark}</span>
                                    </div>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-x mb-1" viewBox="0 0 16 16">
                                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                    </svg>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default Search;
